import { QuizQuestion, QuizSummary } from "../../../types/Quiz";
import RadioButton from "../../Controls/Inputs/RadioButton";

interface QuestionSummaryProps {
    question: QuizQuestion;
    quizSummary: QuizSummary;
}

export const QuestionSummary = (props: QuestionSummaryProps) => {
    const { question, quizSummary } = props;

    const summaryItem = quizSummary.summary.find(
        (item) => item.question_id === question.id
    );

    if (question.is_open_question) {
        if (!summaryItem?.your_answer.answer)
            return <RadioButton label="Brak odpowiedzi" disabled />;

        if (summaryItem.is_correct)
            return (
                <RadioButton
                    key={summaryItem.your_answer.answer}
                    label={String(summaryItem.your_answer.answer)}
                    indicator="success"
                />
            );
        else
            return (
                <>
                    <RadioButton
                        label={String(summaryItem.your_answer.answer)}
                        indicator="error"
                    />
                    <RadioButton
                        label={String(summaryItem.correct_answer.answer)}
                        indicator="success"
                    />
                </>
            );
    } else {
        const isAnythingSelected = !!summaryItem?.your_answer;
        if (!summaryItem?.your_answer.id) {
            return <RadioButton label="Brak odpowiedzi" disabled />;
        } else
            return (
                <>
                    {question.answers.map((answer) => {
                        const isSelected =
                            summaryItem?.your_answer?.id === answer.id;
                        const isCorrect =
                            summaryItem?.correct_answer?.id === answer.id;
                        return (
                            <RadioButton
                                key={answer.id}
                                label={answer.name}
                                indicator={
                                    isAnythingSelected
                                        ? isCorrect
                                            ? "success"
                                            : isSelected
                                            ? "error"
                                            : undefined
                                        : undefined
                                }
                                disabled={!isSelected && !isCorrect}
                            />
                        );
                    })}
                </>
            );
    }
};
