import { IonRadioGroup, useIonRouter, useIonToast } from "@ionic/react";
import { useCallback, useEffect, useState } from "react";
import RadioButton from "../../Controls/Inputs/RadioButton";
import { ModalButton } from "../../Controls/Buttons/ModalButton";
import { QuizButtonsWrapper } from "./QuizButtonsWrapper";
import { PageContentWrapper } from "../../Layout/Page/PageContentWrapper";
import styled from "@emotion/styled";
import { useAppDispatch } from "../../../hooks/redux/useAppDispatch";
import {
    answerCurrentQuestion,
    nextQuestion,
    quizSent,
    resetQuiz,
} from "../../../store/slices/quiz";
import { useAppSelector } from "../../../hooks/redux/useAppSelector";
import Loader from "../../Controls/Loader/Loader";
import Input from "../../Controls/Inputs/Input";
import TimerProgressBar from "../../Controls/ProgressBar/TimerProgressBar";
import { alertCircleOutline } from "ionicons/icons";
import { useSaveQuizScoreMutation } from "../../../services/quiz";
import React from "react";
import { PostContentWrapper } from "../../Layout/Post/PostContentWrapper";
import { PostImageWrapper } from "../../Layout/Post/PostImageWrapper";
import { ProgressCard } from "../../Layout/ProgressCard/ProgressCard";
import { QuestionSummary } from "./QuestionSummary";
import { store } from "../../../store/store";

export const QuestionText = styled("h5")`
    ${({ theme }) => theme.text.h5}
    margin: 0;
`;

export const QuestionTextAmount = styled("h2")`
    ${({ theme }) => theme.text.paragraph.tiny}
    color: ${({ theme }) => theme.colors.primary.gunmetal["640"]};
`;

export const QuestionHeaderWrapper = styled("div")`
    ${({ theme }) => theme.text.h2}
    margin: 40px 0;
`;

export const QuestionsWrapper = styled("div")`
    min-height: 93%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

export const QuizQuestions = () => {
    const router = useIonRouter();

    const dispatch = useAppDispatch();
    const quizState = useAppSelector((state) => state.quiz);
    const user_id = useAppSelector((state) => state.auth.data?.id);

    const defaultAnswerIdValue = !quizState.currentQuestion?.is_open_question
        ? quizState.answers?.find(
              (answer) => answer.question_id === quizState.currentQuestion?.id
          )?.id ?? null
        : null;

    const defaultNumericAnswerValue = quizState.currentQuestion
        ?.is_open_question
        ? quizState.answers?.find(
              (answer) => answer.question_id === quizState.currentQuestion?.id
          )?.answer ?? null
        : null;

    const [answerId, setAnswerId] = useState<number | null>(
        defaultAnswerIdValue
    );

    const [numericAnswer, setNumericAnswer] = useState<number | null>(
        defaultNumericAnswerValue
    );

    const [presentToast] = useIonToast();

    const [
        saveQuizScore,
        { data: quizSummary, isLoading, isError, isUninitialized },
    ] = useSaveQuizScoreMutation();

    useEffect(() => {
        if (quizState.state === "sent" && isUninitialized && !quizSummary) {
            dispatch(resetQuiz());
        }
    }, [dispatch, isUninitialized, quizState.state, quizSummary]);

    useEffect(() => {
        if (quizState.currentQuestion === null) return;
        if (quizState.currentQuestion.is_open_question)
            setNumericAnswer(defaultNumericAnswerValue);
        else setAnswerId(defaultAnswerIdValue);
    }, [
        defaultAnswerIdValue,
        defaultNumericAnswerValue,
        quizState.currentQuestion,
    ]);

    const sendQuiz = useCallback(() => {
        const currentQuizState = store.getState().quiz;

        if (
            currentQuizState.state !== "finished" &&
            currentQuizState.state !== "active"
        )
            return;

        if (!user_id) {
            presentToast({
                message:
                    "Wystąpił błąd z uwierzytelnieniem. Zaloguj się i spróbuj ponownie.",
                icon: alertCircleOutline,
                animated: true,
                position: "top",
                color: "danger",
                duration: 5000,
            });
            return;
        }

        saveQuizScore({
            quiz_id: currentQuizState.quiz.id,
            answers: currentQuizState.answers,
        })
            .unwrap()
            .then(() => {
                dispatch(quizSent());
            })
            .catch(() => {
                // dispatch(resetQuiz());
                presentToast({
                    message:
                        "Wystąpił błąd podczas zapisywania Twojego wyniku.",
                    icon: alertCircleOutline,
                    animated: true,
                    position: "top",
                    color: "danger",
                    duration: 5000,
                });
            });
    }, [dispatch, presentToast, saveQuizScore, user_id]);

    const handleNextQuestion = useCallback(() => {
        if (!quizState.currentQuestion) return;

        if (quizState.currentQuestion.is_open_question) {
            if (numericAnswer !== null) {
                dispatch(
                    answerCurrentQuestion({
                        answer: numericAnswer,
                        question_id: quizState.currentQuestion.id,
                        id: null,
                        name: null,
                    })
                );
            }
        } else {
            const answer = quizState.currentQuestion.answers.find(
                (answer) => answer.id === answerId
            );
            if (answer !== undefined) {
                dispatch(answerCurrentQuestion(answer));
            } else {
                dispatch(
                    answerCurrentQuestion({
                        answer: null,
                        question_id: quizState.currentQuestion.id,
                        id: null,
                        name: null,
                    })
                );
            }
        }

        dispatch(nextQuestion());

        const isLastQuestion =
            quizState.currentQuestionIndex ===
            quizState.quiz.questions.length - 1;
        if (isLastQuestion) sendQuiz();
    }, [
        answerId,
        dispatch,
        numericAnswer,
        quizState.currentQuestion,
        quizState.currentQuestionIndex,
        quizState.quiz?.questions.length,
        sendQuiz,
    ]);

    if (
        quizState.state !== "active" &&
        quizState.state !== "finished" &&
        quizState.state !== "sent"
    )
        return null;

    const isLastQuestion =
        quizState.currentQuestionIndex === quizState.quiz.questions.length - 1;

    const handleGoBack = () => {
        dispatch(resetQuiz());
        router.push("/quizzes/done");
    };

    return (
        <QuestionsWrapper>
            {quizSummary ? (
                <>
                    <PostImageWrapper
                        backgroundUrl={quizState.quiz.photo_path}
                    />
                    <PostContentWrapper
                        before={
                            <ProgressCard
                                inQuizSummary
                                variant={
                                    quizSummary.is_passed ? "green" : "red"
                                }
                                headline={
                                    quizSummary.is_passed
                                        ? "Gratulacje!"
                                        : "Spróbuj jeszcze raz"
                                }
                                text={`${
                                    quizSummary.summary.filter(
                                        (item) => item.is_correct
                                    ).length
                                }/${
                                    quizSummary.summary.length
                                } poprawnych odpowiedzi`}
                                value={quizSummary.score / 100}
                            />
                        }
                    >
                        <PageContentWrapper
                            style={{ padding: "50px 16px 110px 16px" }}
                        >
                            {quizState.quiz.questions.map((question, index) => (
                                <React.Fragment key={question.id}>
                                    <QuestionHeaderWrapper>
                                        <QuestionTextAmount>
                                            Pytanie {index + 1}/
                                            {quizState.quiz.questions.length}
                                        </QuestionTextAmount>
                                        <QuestionText>
                                            {question.name}
                                        </QuestionText>
                                    </QuestionHeaderWrapper>
                                    <QuestionSummary
                                        question={question}
                                        quizSummary={quizSummary}
                                    />
                                </React.Fragment>
                            ))}
                        </PageContentWrapper>
                    </PostContentWrapper>
                </>
            ) : (
                (quizState.state === "active" ||
                    quizState.state === "finished") && (
                    <div>
                        {quizState.state === "active" && (
                            <TimerProgressBar
                                currentQuestion={quizState.currentQuestion}
                                handleNextQuestion={handleNextQuestion}
                            />
                        )}
                        <PageContentWrapper>
                            <div>
                                <QuestionHeaderWrapper>
                                    <QuestionTextAmount>
                                        Pytanie{" "}
                                        {quizState.currentQuestionIndex + 1}/
                                        {quizState.quiz.questions.length}
                                    </QuestionTextAmount>
                                    <QuestionText>
                                        {quizState.currentQuestion.name}
                                    </QuestionText>
                                </QuestionHeaderWrapper>
                                {quizState.currentQuestion.is_open_question ? (
                                    <Input
                                        type="number"
                                        value={numericAnswer}
                                        placeholder="Wpisz odpowiedź"
                                        onChange={(value) =>
                                            setNumericAnswer(value)
                                        }
                                    />
                                ) : (
                                    <IonRadioGroup
                                        value={answerId}
                                        onIonChange={(event) => {
                                            setAnswerId(event.target.value);
                                        }}
                                    >
                                        {quizState.currentQuestion.answers.map(
                                            (answer) => (
                                                <RadioButton
                                                    key={answer.id}
                                                    label={
                                                        answer.name ??
                                                        answer.answer
                                                    }
                                                    value={answer.id}
                                                    disabled={
                                                        quizState.state !==
                                                        "active"
                                                    }
                                                />
                                            )
                                        )}
                                    </IonRadioGroup>
                                )}
                            </div>
                        </PageContentWrapper>
                    </div>
                )
            )}

            <QuizButtonsWrapper>
                {!quizSummary && (
                    <ModalButton
                        onClick={handleNextQuestion}
                        disabled={
                            isLoading ||
                            quizState.currentQuestion?.is_open_question
                                ? numericAnswer === null
                                : answerId === null
                        }
                    >
                        {isLoading ? (
                            <Loader />
                        ) : isLastQuestion ? (
                            "Zapisz odpowiedzi"
                        ) : (
                            "Następne pytanie"
                        )}
                    </ModalButton>
                )}
                {(quizSummary || isError) && (
                    <ModalButton onClick={handleGoBack}>
                        Zakończ quiz
                    </ModalButton>
                )}
            </QuizButtonsWrapper>
        </QuestionsWrapper>
    );
};
